<template lang="pug">
  div
    .desktop
      img(
        width="100"
        height="50"
        v-for="n in 5"
        :key="n"
        src="./images/article-card-placeholder_desktop.svg"
        alt="Placeholder"
        loading="lazy"
      )
    .mobile
      img(
        width="100"
        height="50"
        v-for="n in 5"
        :key="n"
        src="./images/article-card-placeholder_mobile.svg"
        alt="Placeholder"
        loading="lazy"
      )
</template>

<script lang="ts">
  export default defineNuxtComponent({
    name: 'ArticleCardSmallPlaceholder',
  });
</script>

<style lang="scss" scoped>
  .desktop,
  .mobile {
    img {
      width: 100%;
      height: auto;
    }
  }

  .mobile {
    background: white;
    overflow: hidden;
    padding: 0 16px 16px;

    @include tablet {
      display: none;
    }

    img {
      min-height: 120px;
      object-fit: cover;
      object-position: top left;
      padding: 16px 0;

      &:not(:last-child) {
        border-bottom: 1px solid #e7e7e7;
      }

      &:first-child {
        border-top: 1px solid #e7e7e7;
      }
    }
  }

  .desktop {
    display: none;
    background: white;
    overflow: hidden;

    @include tablet {
      display: inherit;
      padding: 0 24px 24px;
    }

    img {
      padding: 24px 0;
      object-fit: none;
      object-position: top left;
      min-height: 152px;

      &:not(:last-child) {
        border-bottom: 1px solid #e7e7e7;
      }

      &:first-child {
        border-top: 1px solid #e7e7e7;
      }
    }
  }
</style>
